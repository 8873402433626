import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import data from '../../dataJson/data.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Helmet } from 'react-helmet';

const DetailPage = () => {
  const { judul } = useParams();
  const item = data.find((item) => item.judul.replace(/\s+/g, '-').toLowerCase() === judul);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);


  

  if (!item) {
    return <p>Item not found!</p>;
  }

  const images = item.images || []; 

  const openSlider = (index) => {
    setIsOpen(true);
    setCurrentIndex(index);
  };

  const closeSlider = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative text-pink-500 lg:max-w-screen flex justify-center mt-[20px] h-sm:mb-20 h-smm:mb-44 h-md:mb-56 h-mdd:mb-80 p-4">
      <Helmet>
        <title>{`Nonton ${item.kategori} - ${item.judul} | CoyTube`}</title>
        <meta
          name="description"
          content={` ${item.kategori} ${item.judul}. ${item.deskripsi || ''}`}
        />
        <meta
          name="keywords"
          content={`${item.kategori} ${item.judul} bokep indo, bokep jepang no sensor, foto bugil indo, bokep barat gangbang`}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="w-[83%] mt-[50px] relative z-10">
        <h1 className="text-2xl font-bold mb-1">{item.judul}</h1>
        <h1 className="text-2xl font-bold mb-1">{item.iframe.length} Video | {item.images.length} Foto </h1>

        {/* Gambar utama dan klik untuk membuka slider */}
        {images.length > 0 && (
           <div className="relative lg:h-auto lg:w-auto flex items-center justify-center bg-cover bg-center cursor-pointer mb-5"
          onClick={() => openSlider(0)}
          style={{ backgroundImage: `url(${item.thumbnail})` }}
        >
          <img
            src={item.thumbnail}
            alt={item.judul}
            className="lg:h-[450px] lg:w-auto relative z-10"
          />
        </div>
        )}
       

        {/* Jika slider terbuka */}
        {isOpen && (
          <div className="fixed inset-0 bg-black flex items-cente justify-center z-50">
            <Swiper
              initialSlide={currentIndex}
              onSlideChange={(swiper) => {
                setCurrentIndex(swiper.activeIndex);
              }}
              slidesPerView={1}
              spaceBetween={10}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
            >
              {images.map((img, index) => (
                <SwiperSlide key={index}>
                  <button
                    onClick={closeSlider}
                    className="absolute right-3 top-3 sm:top-40 bg-gray-700 px-4 py-2 rounded"
                  >
                    X
                  </button>
                  <div className="flex flex-col items-center justify-center h-full h-smm:mt-[-45px] sm:mt-0">
                    <img src={img} alt={`Slide ${index}`} className="w-auto h-auto sm:h-screen mb-4 h-md:mb-16 h-mdd:mb-20 h-lg:mb-24" />
                  </div>
                  <p className="text-white font-bold absolute sm:top-40 h-sm:top-2 h-sm:left-3 h-smm:top-3 h-smm:left-3 bg-black opacity-75 rounded-md">{`${index + 1} / ${images.length}`}</p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {/* Iframe */}
        {item.iframe && item.iframe.map((src, index) => (
          <iframe
            key={index}
            src={src}
            className="w-full sm:h-[500px] h-[300px] mb-4"
            frameBorder="0"
            allowFullScreen
          />
        ))}
      </div>
    </div>
  );
};

export default DetailPage;
