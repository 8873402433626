import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import data from '../../dataJson/data.json'; 
import Footbar from "../../component/footbar/footbar";
import { Helmet, } from "react-helmet";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; 
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Mengambil item dari index terakhir (terbaru) 
  const sortedData = [...data].reverse();

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedData.slice(startIndex, endIndex); // Ambil data dari sortedData

  const navigate = useNavigate();

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      navigate(`?page=${currentPage + 1}`);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      navigate(`?page=${currentPage - 1}`);
    }
  };

  return (
    <div className="p-4 bg-[#343131] h-sm:mb-11 h-smm:mb-28 h-md:mb-72 sm:mb-0">
      <Helmet>
        <title>CoyTube - Koleksi Vidio Bokep Indo, Foto Bugil, Bokep Jepang No Sensor, Bokep Barat</title>
        <link rel="canonical" href="https://www.coytube.xyz/" />
        <meta
          name="description"
          content="vidio bokep indo terbaru, Foto bugil indo, Bokep Jepang no Sensor, Bokep Barat, coytube, avtub, indo18"
        />
        <meta
          name="keywords"
          content="bokep indo, bokep jepang no sensor, 
          bokep barat, foto bugil indo, bahan coli, bacol, bokep viral, abg colmek, bokep hijab, avtub, indo18, coytube"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {currentItems.map((item) => (
          // Mengganti spasi dengan tanda minus dalam URL
          <Link to={`/detail-page/${item.judul.replace(/\s+/g, '-')}`} key={item.id}>
            <div className="border border-gray-300 rounded overflow-hidden shadow-lg">
              <img width='416' height='234' src={item.thumbnail} alt={item.judul} className="w-full h-64 sm:h-60 object-cover" />
              <div className="p-4">
                <h3 className="text-lg text-pink-500 font-bold"> {item.kategori} - {item.judul}</h3>
                <p className="text-pink-500">{item.iframe.length} Video | {item.images.length} Foto</p>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="flex justify-center mt-4 space-x-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Previous
        </button>

        <span className="px-4 py-2 text-pink-500 font-bold ">
          Page {currentPage} of {totalPages}
        </span>

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Next
        </button>
      </div>
      <Footbar/>  
    </div>
  );
};

export default Home;
